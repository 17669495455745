var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"productForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveUpdateChanges.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Cuerpo *"}},[_c('validation-provider',{attrs:{"name":"cuerpo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxLength":"20"},model:{value:(_vm.formData.body),callback:function ($$v) {_vm.$set(_vm.formData, "body", $$v)},expression:"formData.body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Permanente","label-cols":"10"}},[_c('validation-provider',{attrs:{"name":"cuerpo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"name":"is-rtl","switch":"","inline":""},model:{value:(_vm.formData.requireInteraction),callback:function ($$v) {_vm.$set(_vm.formData, "requireInteraction", $$v)},expression:"formData.requireInteraction"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Publico","label-cols":"10"}},[_c('validation-provider',{attrs:{"name":"publico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"name":"is-rtl","switch":"","inline":""},model:{value:(_vm.formData.public),callback:function ($$v) {_vm.$set(_vm.formData, "public", $$v)},expression:"formData.public"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.formData.requireInteraction)?_c('b-form-group',{attrs:{"label":"Duración en Milisegundos *"}},[_c('validation-provider',{attrs:{"name":"tiempo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.timeoutOptions},model:{value:(_vm.formData.timeout),callback:function ($$v) {_vm.$set(_vm.formData, "timeout", $$v)},expression:"formData.timeout"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2582026780)})],1):_vm._e()],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.urlPreview,"variant":"light-success","size":"150px","rounded":""}})]},proxy:true}])},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"input":_vm.uploadImage}}),_c('feather-icon',{staticClass:"d-inline mr-sm-50",attrs:{"icon":"UploadIcon"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Subir")])],1)],1)]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"type":"submit","variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":_vm.modeEdit ? 'EditIcon' : 'SaveIcon',"size":"16"}}),_vm._v(" "+_vm._s(_vm.modeEdit ? 'Actualizar': 'Guardar')+" ")],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"warning","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.preview()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":'BellIcon',"size":"16"}}),_vm._v(" Vista Previa ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }